import React, {useEffect} from "react";
import NavBar from "../components/Navbar";
import NotFoundImage from "../assets/404.png";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { usePostHog } from 'posthog-js/react';
import Metadata from "../components/metadata/MetaData";

const Page404 = () => {
  const { t } = useTranslation();
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture('$pageview');
  }, [posthog]);

  return (
    <div>
      <Metadata
        description="Experience the MahaKumbh in Prayagraj with Dharmik App. Join the spiritual journey and access online puja and prasad services. | प्रयागराज में महाकुंभ का अनुभव करें, धामिक ऐप के साथ। आध्यात्मिक यात्रा में शामिल हों और ऑनलाइन पूजा और प्रसाद सेवाएँ प्राप्त करें।"
        keywords="MahaKumbh, Prayagraj, dharmik, online puja, prasad, Hindu rituals, spiritual experience, Ayodhya, Dharmik App, महाकुंभ, प्रयागराज, ऑनलाइन पूजा, प्रसाद, हिंदू अनुष्ठान, आध्यात्मिक अनुभव"
        ogTitle="MahaKumbh 2025 in Prayagraj | Dharmik App for Online Puja and Prasad | प्रयागराज में महाकुंभ 2025 | ऑनलाइन पूजा और प्रसाद के लिए धामिक ऐप"
        ogDescription="Join the MahaKumbh in Prayagraj with Dharmik App. Enjoy online puja and prasad services for a divine experience. | प्रयागराज में महाकुंभ में शामिल हों, धामिक ऐप के साथ। दिव्य अनुभव के लिए ऑनलाइन पूजा और प्रसाद सेवाओं का आनंद लें।"
        ogImage="https://d3vqmpzcil03dt.cloudfront.net/webite-assets/dharmik_logo.png"
        ogUrl="https://dharmikapp.in"
        canonicalUrl="https://dharmikapp.in"
        twitter="@dharmikapp"
        youtube="@dharmik_hindi"
        instagram="@dharmik_app"
        author="Dharmik App"
        publisher="Dharmik"
        datePublished="2025-01-21"
      />
      <NavBar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff1ea",
          height: "100vh",
        }}
      >
        <img src={NotFoundImage} alt={t("404 Error")} />
        <h1>{t("Page Not Found")}</h1>
        <a href="/" style={{ textDecoration: "none", color: "#ff6200" }}>
          {t("Go back to homepage")}
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default Page404;
