import React, { useState, useEffect, useRef } from "react";
import NavBar from "../../components/Navbar";
import styles from "../../styles/screens/Ayodhya/PujaDetails.module.css";
import Footer from "../../components/Footer";
import {db} from "../../utils/firestoredb";
import { getDoc, doc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import PujaReview from "../../components/PujaReview";
import Step1Icon  from "../../assets/step1.svg";
import Step2Icon  from "../../assets/step2.svg";
import Step3Icon  from "../../assets/step3.svg";
import Step4Icon  from "../../assets/step4.svg";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useLocation } from "react-router-dom";
import Metadata from "../../components/metadata/MetaData";
import { usePostHog } from 'posthog-js/react'
import {Oval} from 'react-loader-spinner';
import CountdownTimer from "../../components/CountdownTimer";

function PujaDetails() {
  const {pujaId} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const selectPujaRef = useRef(null);
  const aboutPujaRef   = useRef(null);
  const benefitsRef    = useRef(null);
  const pujaprocessRef = useRef(null);
  const [pujaReviews, setpujaReviews] = useState([]);
  const [pujaDetails, setPujaDetails] = useState(null);
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState();
  const [devoteesNumber, setDevoteesNumber] = useState("100");
  const [highlight, setHighlight] = useState(true);
  const posthog = usePostHog();

  const queryParams = new URLSearchParams(location.search);
  const langParam = queryParams.get("lang");

  useEffect(() => {
    if(langParam && (langParam === "en" || langParam === "hi")) {
      setLanguage(langParam);
    } else {
      const currentLanguage = i18n.language;
      setLanguage(currentLanguage);
    }
  }, [i18n.language, langParam, language]);

  useEffect(() => {
    if (highlight) {
      const timer = setTimeout(() => {
        setHighlight(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [highlight]);

  useEffect(() => {
      const fetchPujaDetails = async () => {
      const pujaDocRef = doc(db, "ayodhyapuja", pujaId);
      const pujaDocSnapshot = await getDoc(pujaDocRef);

      if (pujaDocSnapshot.exists()) {
        const pujaData = pujaDocSnapshot.data();
        setPujaDetails(pujaData);

        const reviews = pujaData.reviews || [];
        setpujaReviews(reviews);
        
        const devotestring = pujaData.booked_by.en;
        const number = parseInt((devotestring.match(/\d+/) || [100])[0], 10);
        setDevoteesNumber(number);
        console.log("Puja data:", pujaData);
      } else {
        console.log("No such document!");
      }
    };

    fetchPujaDetails();
  }, [pujaId]);

  useEffect(() => {
    posthog.capture('$pageview');
  }, [posthog]);

  if (!pujaDetails) {
    return(
        <Oval 
          color="#ff8e4d"
          height="20%"
          width="20%"
          strokeWidth={4}
          ariaLabel="Loading"
          wrapperStyle={{position: "fixed", top: "45%", left: "45%"}}
          wrapperClass="loader"
        />
    );
  }

  const handleTabChange = (tab) => {
    if(tab === "about puja") {
      scrollToAboutPuja();
    }else if(tab === "benefits") {
      scrollToBenefits();
    }else if(tab === "poojaProcess") {
      scrollToPujaProcess();
    }else if(tab === "selectpuja") {
      scrollToSelectPuja();
    }
    setActiveTab(tab);
    setHighlight(true); 
  };

  const scrollToSelectPuja = () => {
    const element = selectPujaRef?.current;
  
    if (element) {
      const offsetTop = element.getBoundingClientRect().top + window.scrollY;
      const offsetLeft = element.getBoundingClientRect().left + window.scrollX;
  
      window.scroll({
        top: offsetTop,
        left: offsetLeft,
        behavior: 'smooth'
      });
    }
  };

  const scrollToAboutPuja = () => {
    aboutPujaRef?.current.scrollIntoView({behavior: 'smooth'});
  };

  const scrollToBenefits = () => {
    benefitsRef?.current.scrollIntoView({behavior: 'smooth'});
  };

  const scrollToPujaProcess = () => {
    pujaprocessRef?.current.scrollIntoView({behavior: 'smooth'});
  };

  const isLoggedIn = JSON.parse(localStorage.getItem("phoneNumber")) ? true : false;

  return (
    <div className={styles.ayodhya}>
      <Metadata
        description="Dharmik online puja and prasad in Ayodhya"
        keywords="dharmik, dharmikapp, ayodhya, puja, prasad, online puja, online prasad, hindu puja online"
        ogTitle="Dharmik App online puja and prasad in Ayodhya"
        ogDescription="Online puja and prasad in Ayodhya via Dharmik App"
        ogImage="https://d3vqmpzcil03dt.cloudfront.net/webite-assets/dharmik_logo.png"
        ogUrl="https://dharmikapp.in"
        canonicalUrl="https://dharmikapp.in"
        twitter="@dharmikapp"
        youtube="@dharmik_hindi"
        instagram="@dharmik_app"
        author="Dharmik App"
        publisher="Dharmik"
        datePublished="2024-09-06"
       />
      <NavBar />
      <a href={"/puja/" + pujaId} className={styles.back}>
      </a>
      <aside className={styles.listing}>
        `<div className={styles.carouselContainer}>
            <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}>
                {pujaDetails.images[language].map((image, index) => (
                    <div key={index}>
                        <img src={image} alt={`Puja ${index + 1}`}  />
                    </div>
                ))}
            </Carousel>
        </div>`
        <div className={styles.pujaParentDiv}>
          <header className={styles.header}>
            <div className={styles.iconTextWrapper}>
              <h1 className={styles.title}>
                {language === "en"
                  ? pujaDetails.title?.en
                  : pujaDetails.title?.hi}
              </h1>
            </div>
            {pujaDetails.description?.en && (
              <p className={styles.subtitle}>
                {pujaDetails.description[language]}
              </p>
            )}
            {pujaDetails.timer_date === ""? null : (
              <div>
                <p>{t("Puja bookings will be closed in")}</p>
                <CountdownTimer targetDate={pujaDetails.timer_date}/>
              </div>
            ) }

            {(!pujaDetails.slots_available)? null : (
              <div className={styles.limited_slots}>
                <div className={styles.slots_row}>
                <span className={styles.limited_slots_text}>{t("only")}</span>
                <span className={styles.limited_slots_text_counter}>{pujaDetails.slots_available}</span>
                <span className={styles.limited_slots_text}>{t("only limited slots left")}</span>
                </div>
              </div>
            )}

            
            <div className={styles.container}>
              <img src="https://d3vqmpzcil03dt.cloudfront.net/review_images-website/review_image_1.jpg" alt="Dharmik App Online Puja Review" className={`${styles.image0} ${styles.image1}`} />
              <img
  src={`https://d3vqmpzcil03dt.cloudfront.net/review_images-website/review_image_${generateRandomNumbers()}.jpg`}
  alt="Dharmik App Online Puja Review"
  className={`${styles.image0} ${styles.image2}`}
/>
              <img src="https://d3vqmpzcil03dt.cloudfront.net/review_images-website/review_image_19.jpg" alt="Dharmik App Online Puja Review" className={`${styles.image0} ${styles.image3}`} />
              <p className={styles.text1}>
    <strong>{t("Till now")} <span className={styles.colorText}>{devoteesNumber}+ {t("Devotees")}</span> {t("have booked puja")}</strong>
  </p>
</div>
<strong className={styles.reviewsTitle}>{t("reviews")}</strong>
<div className={styles.scrollContainer}>
  {pujaReviews.map((review, i) => (
    <div key={i} style={{ display: 'inline-block', width: '40%', margin: '0 0px' }}>
      <PujaReview {...review} />
    </div>
  ))}
</div>
          </header>
          <div className={styles.bookbutton_parent}>
            <button
              className={styles.bookButton}
              onClick={() => {
                scrollToSelectPuja();
              }}>
              {t("Select Puja")}
            </button>
          </div>
        </div>
      </aside>
      <main className={styles.mainContent}>
      <div className={styles.tabs}>
      <div className={styles.tabContainer}>
          <div className={`${activeTab === "about puja" ? styles.activeTab : styles.tab} ${styles.tabWidth}`} onClick={() => handleTabChange("about puja")}>
            {t("About Puja")}
          </div>
        </div>
        <div className={styles.tabContainer}>
          <div className={`${activeTab === "benefits" ? styles.activeTab : styles.tab} ${styles.tabWidth}`} onClick={() => handleTabChange("benefits")}>
            {t("Benefits")}
          </div>
        </div>
        {pujaDetails.puja_category === false ? null : (
          <div className={styles.tabContainer}>
          <div className={`${activeTab === "poojaProcess" ? styles.activeTab : styles.tab} ${styles.tabWidth}`} onClick={() => handleTabChange("poojaProcess")}>
            {t("Pooja Process")}
          </div>
          </div>
        )}
        <div className={styles.tabContainer}>
          <div className={`${activeTab === "selectpuja" ? styles.activeTab : styles.tab} ${styles.tabWidth}`} onClick={() => handleTabChange("selectpuja")}>
            {t("Select Puja")}
          </div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <section className={styles.features}>
      <div className={`${activeTab === "aboutpuja" ? `${styles.highlightBox} ${!highlight ? styles.noBorder : ''}` : ''}`} ref={aboutPujaRef}>
          <h2>{t("About Puja")}</h2>
            <p className={styles.about_puja_description}>
                {language === "en" ? pujaDetails?.description.en : pujaDetails?.description.hi}
            </p>
        </div>
        <div className={`${activeTab === "benefits" ? `${styles.highlightBox} ${!highlight ? styles.noBorder : ''}` : ''}`} ref={benefitsRef}>
          <h2>{t("Benefits")}</h2>
          <div className={styles.benefits_list}>
            {pujaDetails.benefits?.map((benefit, index) => (
            <div className={styles.benefit_card} key={index}>
            <img src={benefit.image} alt={benefit.title.en} className={styles.benefit_image} />
            <div className={styles.benefit_text}>
            <h3 className={styles.benefit_title}>{benefit.title[language]}</h3>
            <p className={styles.benefit_description}>
            {language === "en" ? benefit.description.en : benefit.description.hi}
         </p>
         </div>
    </div>
  ))}
</div>
        </div>

        {pujaDetails.puja_category === false ? null : (
          <div className={ activeTab === "poojaProcess" ? `${styles.highlightBox} ${!highlight ? styles.noBorder : ''}` : '' } ref={pujaprocessRef}>
          <h2>{t("Pooja Process")}</h2>
          <div className={styles.pujadetails_scroll_container}>
            {Array.from({ length: 5 }, (_, index) => (
              <div key={index} className={styles.pujadetails_item}>
                <div className={styles.pujadetails_card}>
                  <div className={styles.pujadetails_card_inner}>
                    <div className={styles.pujadetails_card_content}>
                      <div className={styles.pujadetails_card_flex}>
                        <div className={styles.pujadetails_icon_wrapper}>
                          <div className={styles.pujadetails_icon_container}>
                            <img
                              src={index === 0 ? Step1Icon : index === 1 ? Step2Icon : index === 2 ? Step3Icon : Step4Icon}
                              alt="Puja Process"
                            />
                          </div>
                        </div>
                      <div style={{ flex: 1, minWidth: 0 }}>
                        <h3 className={styles.pujadetails_step_title}>{t("step")} {index + 1}</h3>
                        <p className={styles.pujadetails_step_description}>
                          {index === 0
                            ? t("puja process 1")
                            : index === 1
                            ? t("puja process 2")
                            : index === 2
                            ? t("puja process 3")
                            : index === 3
                            ? t("puja process 4")
                            : index === 4
                            ? t("puja process 5")
                            : t("puja process 5")}
                        </p>
                      </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
    ))}
  </div>
</div>
        )}

        <div className={`${activeTab === "selectpuja" ? `${styles.highlightBox} ${!highlight ? styles.noBorder : ''}` : ''}`} ref={selectPujaRef}>
          <h2>{t("Select Puja")}</h2>
          <div className={styles.offerings_container}>
            {pujaDetails.offerings?.map((offering, index) => (
            <Link to={"/pujainfo/"+ pujaId +"/" + (index+1)} key={index}>
              <div className={styles.offering_card}>
                <img src={offering.image} alt={offering.title} className={styles.offering_image} />
                <div className={styles.offering_content}>
                    <h3 className={styles.offering_title}>{offering.title[language]}</h3>
                    <div className={styles.offering_text_container}>
                      <span className={styles.offering_text_right}>{offering.peoplelabel[language]}</span>
                      <span className={styles.offering_text_left}>
                              {t("Book for")}
                              {offering.currency != null ? (offering.currency === "INR" ? "₹ " : "$ ") : "₹ "}
                              {offering.discounted_price != null ? (
                                <>
                                  <span style={{ textDecoration: 'line-through' }}>
                                    {offering.discounted_price.originalprice}
                                  </span>
                                  <span style={{ fontWeight: 'bold' }}>
                                    {" " + offering.discounted_price.discountedprice}
                                  </span>
                                  <br/>
                                  <span style={{ fontWeight: 'bold', color:"#FF0000"}}>
                                    {t("Offer ends today")}
                                  </span>
                                </>
                              ) : (
                                offering.price
                              )}
                      </span>
                    </div>
                        <p className={styles.offering_description}>{offering.description[language]}</p>
                        <ul className={styles.puja_process_list}>
                          {offering.pujaprocess?.map((process, index) => {
                            const text = process[language];

                            const [leftPart, rightPart] = text.split(":");

                            return (
                              <li key={index} className={styles.puja_process_item}>
                                <strong>{leftPart}</strong>: {rightPart}
                              </li>
                            );
                          })}
                          </ul>
                        <button className={styles.book_button} onClick={() => {
                          if(isLoggedIn) navigate("/pujainfo/"+ pujaId +"/" + (index+1))
                          else navigate("/login/" + pujaId + "/" + (index+1))
                        }}>
                              {t("Book for")}
                              {offering.currency != null ? (offering.currency === "INR" ? "₹" : "$") : "₹"}
                              {offering.discounted_price != null ? (
                                <>
                                  <span style={{ textDecoration: 'line-through' }}>
                                    {offering.discounted_price.originalprice}
                                  </span>
                                  <span style={{ fontWeight: 'bold' }}>
                                    {offering.discounted_price.discountedprice}
                                  </span>
                                </>
                              ) : (
                                offering.price
                              )}
                        </button>
                </div>
            </div>
            </Link>
                ))}
            </div>
        </div>
      </section>
    </main>
      <Footer />
    </div>
  );
}
function generateRandomNumbers() {
  return Math.floor(Math.random() * 19) + 2;
}
export default PujaDetails;
