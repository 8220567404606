import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../styles/components/Footer.module.css";
import logo from "../assets/logo_white.svg";
import { ReactComponent as FacebookIcon } from "../assets/facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/instagram.svg";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";
import { ReactComponent as LinkedInIcon } from "../assets/linkedin.svg";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.footer}>
      <div className={styles.footer_content}>
        <div className={styles.logo_description_container}>
          <a href="/">
          <img src={logo} alt="Dharmik App online puja prasad Ayodhya" className={styles.footer_logo} />
          </a>
          <p className={styles.footer_description}>
            {t("footermsg")}
          </p>
        </div>
        <div className={styles.footer_contact}>
          <div className={styles.contact_info}>
            <a href="tel:+917906671664">{t("Contact Us")}</a>
            <a href="tel:+917906671664">+91 7906671664</a>
            <a href="mailto:contact@dharmikapp.in">contact@dharmikapp.in</a>
            <a href="address:Kansal Residency, Mehrauli, Delhi 110030">
              Kansal Residency, Mehrauli, Delhi 110030
            </a>
          </div>
          <div className={styles.footer_social_links}>
            <a href="https://www.facebook.com/GharKaMandir">
              <FacebookIcon className={styles.social_icon} />
            </a>
            <a href="https://www.instagram.com/dharmik_app/">
              <InstagramIcon className={styles.social_icon} />
            </a>
            <a href="https://twitter.com/dharmikapp">
              <TwitterIcon className={styles.social_icon} />
            </a>
            <a href="https://www.linkedin.com/company/dharmik-app/">
              <LinkedInIcon className={styles.social_icon} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.spacer} />
      <div className={styles.footer_base}>
        <div className={styles.rights_reserved}>
          {t("2025 Dharmik. All right reserved.")}
        </div>
        <div className={styles.footer_policy_links}>
          <a href="/about-us">{t("About Us")}</a>
          <a href="/privacy-policy">{t("Privacy Policy")}</a>
          <a href="/terms-and-conditions">{t("Terms of Service")}</a>
          <a href="/cancellation-policy">
            {t("Cancellation and Refund Policy")}
          </a>
          <a href="/shipment-policy">{t("Shipping and Exchange Policy")}</a>
          <a href="/sitemap.xml" style={{ color: '#ff8e4d' }}>{t("sitemap")}</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
