import React, { useState, useEffect } from "react";
import NavBar from "../../components/Navbar";
import styles from "../../styles/screens/Profile/Profile.module.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ellipseIcon from "../../assets/ellipse.png";
import BasicDetails from "./BasicDetails";
import { ReactComponent as FacebookIcon } from "../../assets/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/linkedin.svg";
import firebaseConfig from "../../firebase";
import { initializeApp } from "firebase/app";
import {Oval} from 'react-loader-spinner';
import {
  query,
  where,
  getDocs,
  getFirestore,
  doc,
  getDoc,
  collection,
} from "firebase/firestore";
import SavedAddress from "./SavedAddress";
import { usePostHog } from 'posthog-js/react'

const ProfilePage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const phoneNumber = JSON.parse(localStorage.getItem("phoneNumber"));
  const [language, setLanguage] = useState("en");
  const [orderHistory, setOrderHistory] = useState([]);
  const posthog = usePostHog();

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    const fetchUsers = async () => {
      const q = query(
        collection(db, "users"),
        where("phoneNumber", "==", phoneNumber)
      );
      const querySnapshot = await getDocs(q);
      const usersData = [];
      querySnapshot.forEach((doc) => {
        usersData.push(doc.data());
      });
    };

    const fetchOrderHistory = async () => {
      const orderPendingRef = doc(db, "order_history", phoneNumber);
      const querySnapshot = await getDoc(orderPendingRef);
      setOrderHistory(
        querySnapshot.data() ? Object.values(querySnapshot.data()) : []
      );
    };

    fetchUsers();
    fetchOrderHistory();
  }, [db, phoneNumber]);

  useEffect(() => {
    posthog.capture('$pageview');
  }, [posthog]);

  const [panelOption, setPanelOption] = useState("orders");

  const handlePanelOptionChange = (option) => {
    setPanelOption(option);
  };

  let panelContent;
  if (panelOption === "basic") {
    panelContent = <BasicDetails />;
  } else if (panelOption === "orders") {
    panelContent = (
      <div style={{ margin: "1.5rem" }}>
        {orderHistory.length > 0 ? (
          orderHistory.filter((order) => order.rzp_status === "paid").map((order) => (
            <div key={order.orderId} className={styles.orderCard}>
              <a
                href={
                  "/booking/results/" + order.pujaId + "/" + order.offeringId + "/" + order.orderId
                }
                className={styles.status}
              >
                <img
                  src={ellipseIcon}
                  alt="status"
                  className={styles.statusIcon}
                />
                {language === "en" ? order.status : order.hindi_status}
              </a>
              <div className={styles.detailsCard}>
                <img
                  src={order.image}
                  alt={order.pujaname}
                  className={styles.image}
                />
                <div className={styles.details}>
                  <div className={styles.pujaname}>
                    {language === "en" ? order.pujaname : order.hindi_pujaname}
                    <div></div>
                    <span className={styles.pujadescription}>
                      {t("Benefits") +
                        " : " +
                        (language === "en"
                          ? order.pujadescription
                          : order.hindi_pujadescription)}
                    </span>
                  </div>

                  <div className={styles.price_container}>
                    <div className={styles.border}></div>
                    <div className={styles.price}>
                      {t("Price") + " : ₹ " + order.price}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Oval 
                  color="#ff8e4d"
                  height="20%"
                  width="20%"
                  strokeWidth={4}
                  ariaLabel="Loading"
                  wrapperStyle={{position: "fixed", top: "45%", left: "45%"}}
                  wrapperClass="loader"
                />
        )}
        {orderHistory.length > 0 && (
          <div>{t("Showing orders : ") + orderHistory.length}</div>
        )}
      </div>
    );
  } else if (panelOption === "savedAddress") {
    panelContent = <SavedAddress />;
  } else if (panelOption === "loginLogout") {
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("referral_code");
    localStorage.removeItem("invitedBy");
    localStorage.removeItem("saved_address");
    navigate("/profile");
  }

  return (
    <div>
      <NavBar />
      <div className={styles.profileContainer}>
        <ToastContainer />
        <div className={styles.mainContent}>
          <div className={styles.panel}>
            <div className={styles.panelOptions}>
              <span className={styles.c}>{t("Profile")}</span>
              <button onClick={() => handlePanelOptionChange("basic")}>
                {t("Your Details")}
              </button>
              <button onClick={() => handlePanelOptionChange("orders")}>
                {t("Your Orders")}
              </button>
              <button onClick={() => handlePanelOptionChange("savedAddress")}>
                {t("Saved Address")}
              </button>
              <button onClick={() => handlePanelOptionChange("loginLogout")}>
                {t("Log out")}
              </button>
            </div>
            <div className={styles.footer_contact}>
              <div className={styles.contact_info}>
                {t("Contact Us")}
                <a href="mailto:contact@dharmikapp.in">contact@dharmikapp.in</a>
                <a href="tel:+919258412314">+91 9258412314</a>
                <span>Kansal Residency, Mehrauli,</span>
                <span>New Delhi, India</span>
              </div>
              <div className={styles.footer_social_links}>
                <a href="https://www.facebook.com/GharKaMandir">
                  <FacebookIcon className={styles.social_icon} />
                </a>
                <a href="https://www.instagram.com/dharmik_app/">
                  <InstagramIcon className={styles.social_icon} />
                </a>
                <a href="https://twitter.com/dharmikapp">
                  <TwitterIcon className={styles.social_icon} />
                </a>
                <a href="https://www.linkedin.com/company/dharmik-app/">
                  <LinkedInIcon className={styles.social_icon} />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.contentArea}>{panelContent}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
