async function getOrderId(pujaPrice, currency) {
  const tmp = Math.round(pujaPrice).toString();
  const response = await fetch("https://createorder-ptmfa2eama-uc.a.run.app", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ amount: tmp * 100, currency : currency }),
  });
  
  if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error generating Order ID: ${errorData.error.description}`);
    }
  const data = await response.json();
  return data.id;
}

export default getOrderId;
