import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../components/Navbar";
import styles from "../styles/screens/PujaInfo.module.css";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import firebaseConfig from "../firebase";
import { initializeApp } from "firebase/app";
import { useNavigate } from "react-router-dom";
import Metadata from "../components/metadata/MetaData";
import {
  getFirestore,
  doc,
  getDoc,
} from "firebase/firestore";
import getOrderId from "../utils/OrderId";
import CreatePujaOrdersFDB from "../components/Payment/CreatePujaOrderFDB";
import PujaProgressLine from "../components/PujaProgressLine";
import { usePostHog } from 'posthog-js/react';
import {Oval} from 'react-loader-spinner';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


const PujaInfo = () => {

  const { pujaId, offeringId } = useParams();
  const [language, setLanguage] = useState("en");
  const [pujaDetails, setPujaDetails] = useState(null);
  const [orderCurrency, setOrderCurrency] = useState("INR");
  const [finalPujaPrice, setFinalPujaPrice] = useState("");
  const navigate = useNavigate();
  const [paymentOptionSelected, setPaymentOptionSelected] = useState("online");
  const { t, i18n } = useTranslation();
  const [onlineOrderId, setOnlineOrderId] = useState(null);
  const [codOrderId, setCodOrderId] = useState(null);
  const [finalOrderId, setFinalOrderId] = React.useState(null);
  const [showHint, setShowHint] = React.useState(true);
  const [inputValue, setInputValue] = React.useState('');
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [isCouponValid, setIsCouponValid] = React.useState(false);
  const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);
  const [originalPujaPrice, setOriginalPujaPrice] = React.useState("");
  const [codCharges, setCodCharges] = React.useState(100);
  const [isCODOrder, setIsCODOrder] = React.useState(false);
  const posthog = usePostHog();

  const phone = JSON.parse(localStorage.getItem("phoneNumber"));
  const isLoggedIn = JSON.parse(localStorage.getItem("phoneNumber")) ? true : false;

  useEffect(() => {
    console.log('INPUT VALUE CHANGED TO - ', inputValue);
  }, [inputValue]);

  useEffect(() => {
    console.log('FINAL PUJA PRICE AFTER DISCOUNT - ', finalPujaPrice);
    console.log('DISCOUNT AMOUNT FINALLY : ', discountAmount);
  }, [finalPujaPrice, discountAmount]);

  // langugage Intl
  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);


  //fetch puja details
  useEffect(() => {
    let pujaDocRef;
    const fetchPujaDetails = async () => {
      pujaDocRef = doc(db, "ayodhyapuja", pujaId);
      const pujaDocSnap = await getDoc(pujaDocRef);

      if (pujaDocSnap.exists()) {
        const pujaData = pujaDocSnap.data();
        setPujaDetails(pujaData);
        if(pujaData.offerings[offeringId-1].currency!==null)
        setOrderCurrency(pujaData.offerings[offeringId-1].currency);
        setOriginalPujaPrice(pujaData.offerings[offeringId-1].price);
        setFinalPujaPrice(pujaData.offerings[offeringId-1].price);
        setDiscountAmount(0);
        setCodCharges(parseInt(pujaData.offerings[offeringId-1].codcharge));

      } else {
        console.log("Error Fetching Puja Details for Puja: ", pujaId);
      }
    };

    fetchPujaDetails();
  }, [pujaId, offeringId]);

  // default orderId generation
  useEffect (() => {
    const getPujaOrderId = async () => {
      if(pujaDetails) {
        const orderId = await getOrderId(pujaDetails.offerings[offeringId-1].price, orderCurrency);
      if(orderId) {
        setOnlineOrderId(orderId);
        setFinalOrderId(orderId);
      }

      if(pujaDetails.offerings[offeringId-1].codavailable) {
        const codPujaOrderId = await getOrderId(`${+pujaDetails.offerings[offeringId-1].price + +pujaDetails.offerings[offeringId-1].codcharge}`, orderCurrency);
        setCodOrderId(codPujaOrderId);
      }
      
      }
    }
    getPujaOrderId();
  }, [offeringId, pujaDetails, orderCurrency]);

  //posthog impl
  useEffect(() => {
    posthog.capture('$pageview');
  }, [posthog]);


  // navigate to Login page first for automated used details usage
  if(!isLoggedIn) navigate("/login/" + pujaId + "/" + offeringId);

  if (!pujaDetails && !finalOrderId) {
    return(
      <Oval 
        color="#ff8e4d"
        height="20%"
        width="20%"
        strokeWidth={4}
        ariaLabel="Loading"
        wrapperStyle={{position: "fixed", top: "45%", left: "45%"}}
        wrapperClass="loader"
      />
  );
  } else {
    //console.log("Data is loading...");
  }

  const handleFocus = () => {
    setShowHint(false);
  };

  const handleBlur = () => {
    if (inputValue === '') {
      setShowHint(true);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value.toString());
    setIsCouponValid(false);
    setDiscountAmount(0);
    if(originalPujaPrice !== "") {
      setFinalPujaPrice(originalPujaPrice);
      if(paymentOptionSelected === "online") {
        setFinalOrderId(onlineOrderId);
      } else if(paymentOptionSelected === "cod") {
        setFinalOrderId(codOrderId);
      }
    }
  };

  const validateCouponCode = async () => {
    setIsApplyingCoupon(true);
    if(inputValue === ''){
      toast.error(t("Coupon Code is empty"), {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    } else {
    // validate coupon code from the firestore and reset the finalPuajPrice
    const couponCodeRef =  doc(db, "coupons", inputValue);
    const couponCodeDoc = await getDoc(couponCodeRef);
  
    if(couponCodeDoc.exists()) {
      const couponData = couponCodeDoc.data();
      console.log('COUPON DATA : ', couponData);
      const percent = couponData.discount.percentage;
      const max = couponData.discount.max;
  
      let discountAmt = 0;
  
      // Check if percentage is specified
      if (percent !== '') {
        discountAmt = (parseInt(originalPujaPrice) * parseInt(percent)) / 100;
      }
  
      // Check if max discount is specified
      if (max !== '') {
        const maxDiscount = parseInt(max);
        if (percent === '') {
          discountAmt = maxDiscount;
        } else {
          discountAmt = Math.min(discountAmt, maxDiscount);
        }
      }
  
      // Ensure discount amount does not exceed original puja price
      discountAmt = Math.min(discountAmt, parseInt(originalPujaPrice));
      console.log('DISCOUNT AMOUNT : ', discountAmt);
      setDiscountAmount(discountAmt);
      console.log('DISCOUNT AMOUNT FINALLY : ', discountAmount);
  
      // Update the final puja price
      var updatedPujaPrice= (parseInt(originalPujaPrice) - discountAmt).toString();
      if(isCODOrder) {
         updatedPujaPrice = (parseInt(updatedPujaPrice) + codCharges).toString();
      }
      setFinalPujaPrice(updatedPujaPrice);
      console.log('FINAL PUJA PRICE AFTER DISCOUNT - ', finalPujaPrice);

      const orderId = await getOrderId(updatedPujaPrice, orderCurrency);
      
      if(orderId) {
        setFinalOrderId(orderId);
      }
      setIsCouponValid(true);
    } else {
      setIsCouponValid(false);
      setDiscountAmount(0);
      toast.error(t("Invalid Coupon Code"), {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    }
    setIsApplyingCoupon(false);
  };

  const onClickBookPuja = () => {

    if(paymentOptionSelected === "online") {
      console.log("ONLINE ORDER ID: ", finalOrderId);
      if (finalOrderId) {
        CreatePujaOrdersFDB(phone, pujaId, offeringId, finalOrderId, finalPujaPrice,  pujaDetails, paymentOptionSelected, isCouponValid ? inputValue : '', orderCurrency);
      } else {
        console.log("NO ORDER ID FOR ONLINE TRANSACTION");
      }
    }else if(paymentOptionSelected === "cod") {
      console.log("COD ORDER ID : ", codOrderId);
      if(codOrderId) {
        CreatePujaOrdersFDB(phone, pujaId, offeringId, codOrderId, finalPujaPrice,  pujaDetails, paymentOptionSelected, isCouponValid ? inputValue : '', orderCurrency);
      }else {
        console.log("NO ORDER ID FOR COD BOOKING");
      }
    }
    }

  const handlePaymentOptionChange = (event) => {
    const paymentOptionSelected = event.target.value;
  
    if (paymentOptionSelected === "online") {
      setPaymentOptionSelected("online");
      setIsCODOrder(false);
      setFinalPujaPrice(pujaDetails.offerings[offeringId-1].price);
    } else if (paymentOptionSelected === "cod") {
      setPaymentOptionSelected("cod");
      setIsCODOrder(true);
      setFinalPujaPrice(`${+finalPujaPrice + +codCharges}`)
    }
  };

  const isOrderIdSet = () => {
    if (paymentOptionSelected === "online") {
      return finalOrderId === null;
    } else if (paymentOptionSelected === "cod") {
      return codOrderId === null;
    }
    return true;
  };

  const isButtonDisabled = isOrderIdSet();

  const bookButtonText = isButtonDisabled 
    ? t("pleasewait") : (paymentOptionSelected === "online" ? t("Pay Shulk") : t("confirm cod"));

  
    return (
      <div>
        <Metadata
        description="Experience the MahaKumbh in Prayagraj with Dharmik App. Join the spiritual journey and access online puja and prasad services. | प्रयागराज में महाकुंभ का अनुभव करें, धामिक ऐप के साथ। आध्यात्मिक यात्रा में शामिल हों और ऑनलाइन पूजा और प्रसाद सेवाएँ प्राप्त करें।"
        keywords="MahaKumbh, Prayagraj, dharmik, online puja, prasad, Hindu rituals, spiritual experience, Ayodhya, Dharmik App, महाकुंभ, प्रयागराज, ऑनलाइन पूजा, प्रसाद, हिंदू अनुष्ठान, आध्यात्मिक अनुभव"
        ogTitle="MahaKumbh 2025 in Prayagraj | Dharmik App for Online Puja and Prasad | प्रयागराज में महाकुंभ 2025 | ऑनलाइन पूजा और प्रसाद के लिए धामिक ऐप"
        ogDescription="Join the MahaKumbh in Prayagraj with Dharmik App. Enjoy online puja and prasad services for a divine experience. | प्रयागराज में महाकुंभ में शामिल हों, धामिक ऐप के साथ। दिव्य अनुभव के लिए ऑनलाइन पूजा और प्रसाद सेवाओं का आनंद लें।"
        ogImage="https://d3vqmpzcil03dt.cloudfront.net/webite-assets/dharmik_logo.png"
        ogUrl="https://dharmikapp.in"
        canonicalUrl="https://dharmikapp.in"
        twitter="@dharmikapp"
        youtube="@dharmik_hindi"
        instagram="@dharmik_app"
        author="Dharmik App"
        publisher="Dharmik"
        datePublished="2025-01-21"
      />
        <NavBar />
        <ToastContainer/>
        <PujaProgressLine activeStep={2}/>
        <div className={styles.result}>
          <div className={styles.cardContainer}>
            <div className={styles.card}>
              <section className={styles.section}>
                <div className={styles.puja_image_container}>
                <img
                  src={pujaDetails.banner_image[language]}
                  alt="Puja"
                  className={styles.pujaImage}
                />
                </div>
              </section>
              <aside className={styles.aside}>
                <div className={styles.title_container}>
                  <h3 className={styles.pujaTitle}>
                    {language === "en"
                      ? pujaDetails.title?.en
                      : pujaDetails.title?.hi}
                  </h3>
                </div>
                <section className={styles.features}>
                  <div className={styles.puja_description}>
                      <span>{language === "en" ? pujaDetails.offerings[offeringId-1].description?.en : pujaDetails.offerings[offeringId-1].description?.hi}</span>
                    </div>
                </section>
                <div className={styles.shulk_container}>
                    <div className={styles.pay_shulk}>
                        <span>{t("Puja Shulk")}</span>
                        <span>{(orderCurrency === "INR" ? t("rupeesign") : t("dollarsign")) + pujaDetails.offerings[offeringId-1].price}</span>
                    </div>
                    {(isCouponValid) ? (
                      <div className={styles.pay_shulk}>
                        <span>{t("Coupon Discount")}</span>
                        <span>{"- " + discountAmount}</span>
                      </div>
                    ) : null}
                    {(paymentOptionSelected === "cod") ? (
                      <div className={styles.pay_shulk}>
                        <span>{t("COD Shulk")}</span>
                        <span>{"+ " +  codCharges}</span>
                      </div>
                    ) : null}
                    <div className={styles.total_shulk}>
                        <span>{t("Total Shulk")}</span>
                        <span>{(orderCurrency === "INR" ? t("rupeesign") : t("dollarsign")) + finalPujaPrice}</span>
                      </div>
                    <div className={styles.additional_details}>
                        {t("bookingInfoDetails")}
                    </div>
                </div>
                <div className="radioGroup">
                    <div className="radioLabel">
                        <input
                            type="radio"
                            id="online"
                            name="online"
                            value="online"
                            checked={paymentOptionSelected === "online"}
                            onChange={handlePaymentOptionChange}
                        />
                        <label htmlFor="onlinepayment">{t("Online Payment")}</label>
                    </div>
                    {(pujaDetails.offerings[offeringId-1].codavailable) ? (
                      <div className="radioLabel">
                      <input
                          type="radio"
                          id="cod"
                          name="cod"
                          value="cod"
                          checked={paymentOptionSelected === "cod"}
                          onChange={handlePaymentOptionChange}
                      />
                      <label htmlFor="withPrasad">{t("COD Payment")}</label>
                  </div>
                    ) : null}
                </div>
                <div className={styles.coupon_code_input_container}>
                  <input
                    type="text"
                    id="coupon_code"
                    name="coupon_code"
                    placeholder={showHint ? t('couponcode') : ''}
                    className={styles.coupon_code_input}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleInputChange}
                    value={inputValue}
                  />
                  <button className={styles.apply_button} onClick={validateCouponCode}>{isApplyingCoupon ? 'wait ..' : (isCouponValid ? t('applied') : t('Apply Coupon'))}</button>
                </div>
                <div className={styles.book_button_container}>
                    <button className={styles.book_button} onClick={onClickBookPuja} disabled={isButtonDisabled}>{t(bookButtonText)}</button>
                </div>
              </aside>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );

};

export default PujaInfo;