import React, { useState } from "react";
import NavBar from "../components/Navbar";
import styles from "../styles/screens/Waitlist.module.css";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { getAnalytics, logEvent } from "firebase/analytics";
import Metadata from "../components/metadata/MetaData";

import firebaseConfig from "../firebase";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

const Waitlist = () => {
  const { t } = useTranslation();
  const { message } = useParams();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const analytics = getAnalytics(app);
  logEvent(analytics, "Visited: Waitlist Page for "+message);

  async function writeWaitlistData(name, phone) {
    try {
      const docRef = await addDoc(collection(db, "waitlist"), {
        name,
        phone,
        feature_request: message,
        time: serverTimestamp(),
      });

      console.log("Document written with ID: ", docRef.id);

      toast.success("Message sent succesfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    await writeWaitlistData(name, phone);
  };

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  return (
  <div>
    <Metadata
        description="Experience the MahaKumbh in Prayagraj with Dharmik App. Join the spiritual journey and access online puja and prasad services. | प्रयागराज में महाकुंभ का अनुभव करें, धामिक ऐप के साथ। आध्यात्मिक यात्रा में शामिल हों और ऑनलाइन पूजा और प्रसाद सेवाएँ प्राप्त करें।"
        keywords="MahaKumbh, Prayagraj, dharmik, online puja, prasad, Hindu rituals, spiritual experience, Ayodhya, Dharmik App, महाकुंभ, प्रयागराज, ऑनलाइन पूजा, प्रसाद, हिंदू अनुष्ठान, आध्यात्मिक अनुभव"
        ogTitle="MahaKumbh 2025 in Prayagraj | Dharmik App for Online Puja and Prasad | प्रयागराज में महाकुंभ 2025 | ऑनलाइन पूजा और प्रसाद के लिए धामिक ऐप"
        ogDescription="Join the MahaKumbh in Prayagraj with Dharmik App. Enjoy online puja and prasad services for a divine experience. | प्रयागराज में महाकुंभ में शामिल हों, धामिक ऐप के साथ। दिव्य अनुभव के लिए ऑनलाइन पूजा और प्रसाद सेवाओं का आनंद लें।"
        ogImage="https://d3vqmpzcil03dt.cloudfront.net/webite-assets/dharmik_logo.png"
        ogUrl="https://dharmikapp.in"
        canonicalUrl="https://dharmikapp.in"
        twitter="@dharmikapp"
        youtube="@dharmik_hindi"
        instagram="@dharmik_app"
        author="Dharmik App"
        publisher="Dharmik"
        datePublished="2025-01-21"
      />
      <NavBar />
      <div className={styles.comingsoon}>
        <ToastContainer />
        <div className={styles.dialogBox}>
          <form onSubmit={handleSubmit}>
            <h2>{t("Thanks for getting in touch for")+" " + message}</h2>
            <h2>{t("We will contact you for further details")}</h2>
            <label htmlFor="name">{t("Name")}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => handleInputChange(e, setName)}
              required
            />

            <label htmlFor="phone">{t("Phone Number")}</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => handleInputChange(e, setPhone)}
            />

            <button type="submit" className={styles.submitButton}>
              {t("Submit")}
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Waitlist;
