import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import firebaseConfig from "../../firebase";
import RazorpayOnlinePay from "./RazorpayOnlinePay";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const CreatePujaOrdersFDB = async (phone, pujaId, offeringId, orderId, pujaPrice, pujaDetails, paymentMethod, couponCode, currency) => {
  // create order in orders collection
    const pujaDescription = pujaDetails.offerings[offeringId-1].title.en + " - " + pujaDetails.offerings[offeringId-1].peoplelabel.en ;
    const orderDocData = {[orderId]:{
        name: "",
        phone,
        platform: "web",
        pujaname: pujaDetails.offerings[offeringId-1].title?.en,
        pujadescription: pujaDescription,
        price: pujaPrice,
        orderId: orderId,
        pujaId: pujaId,
        offeringId: offeringId,
        paymentMethod: paymentMethod,
        image: pujaDetails.offerings[offeringId-1].image,
        quantity: '1',
        coupon: couponCode,
        currency : currency,
        status: "PAYMENT_INITIATED",
        created_time: serverTimestamp(),
        updated_time: serverTimestamp(),
      }};

    // orders doc
    try {
      const ordersRef = doc(db, "orders", orderId);
      await setDoc(ordersRef, orderDocData);
    } catch (e) {
      console.error("Error in adding document in ORDERS COLLECTION: ", e);
    }

    // order history doc
    try {
        const orderHistoryRef = doc(db, "order_history", phone);
        await updateDoc(orderHistoryRef, orderDocData);
    } catch(e) {
        console.error("ERROR IN CREATING ORDER DOC IN USER HISTORY :", e);
    }

    if(paymentMethod === "cod") {
        try {
            const codOrderRef = doc(db, "cod_order", orderId);
            await setDoc(codOrderRef, orderDocData);
        } catch(e) {
            console.error("ERROR IN CREATING DOC IN COD : ", e);
        }
        window.location.href = window.location.origin + "/bookingform/" + pujaId + "/" + offeringId + "/" + orderId;
    } else if(paymentMethod === "online") {
        RazorpayOnlinePay(
            pujaId,
            offeringId,
            orderId,
            pujaPrice,
            phone,
            pujaDescription,
            phone
        );
    }
};

export default CreatePujaOrdersFDB;
