import React, {useEffect} from "react";
import NavBar from "../components/Navbar";
import styles from "../styles/screens/AboutUs.module.css";
import temple from "../assets/ic_temple.png";
import Footer from "../components/Footer";

import adarshImage from "../assets/team/adarsh.png";
import adityaImage from "../assets/team/aditya.png";
import sanklapImage from "../assets/team/sanklap.png";
import { useTranslation } from "react-i18next";
import Metadata from "../components/metadata/MetaData";
import { usePostHog } from 'posthog-js/react'

const AboutUs = () => {
  const { t } = useTranslation();
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture('$pageview');
  }, [posthog]);

  return (
    <div>
    <Metadata
        description="Experience the MahaKumbh in Prayagraj with Dharmik App. Join the spiritual journey and access online puja and prasad services. | प्रयागराज में महाकुंभ का अनुभव करें, धामिक ऐप के साथ। आध्यात्मिक यात्रा में शामिल हों और ऑनलाइन पूजा और प्रसाद सेवाएँ प्राप्त करें।"
        keywords="MahaKumbh, Prayagraj, dharmik, online puja, prasad, Hindu rituals, spiritual experience, Ayodhya, Dharmik App, महाकुंभ, प्रयागराज, ऑनलाइन पूजा, प्रसाद, हिंदू अनुष्ठान, आध्यात्मिक अनुभव"
        ogTitle="MahaKumbh 2025 in Prayagraj | Dharmik App for Online Puja and Prasad | प्रयागराज में महाकुंभ 2025 | ऑनलाइन पूजा और प्रसाद के लिए धामिक ऐप"
        ogDescription="Join the MahaKumbh in Prayagraj with Dharmik App. Enjoy online puja and prasad services for a divine experience. | प्रयागराज में महाकुंभ में शामिल हों, धामिक ऐप के साथ। दिव्य अनुभव के लिए ऑनलाइन पूजा और प्रसाद सेवाओं का आनंद लें।"
        ogImage="https://d3vqmpzcil03dt.cloudfront.net/webite-assets/dharmik_logo.png"
        ogUrl="https://dharmikapp.in"
        canonicalUrl="https://dharmikapp.in"
        twitter="@dharmikapp"
        youtube="@dharmik_hindi"
        instagram="@dharmik_app"
        author="Dharmik App"
        publisher="Dharmik"
        datePublished="2025-01-21"
      />
      <NavBar />
      <div style={{ backgroundColor: "#fff1ea" }}>
        <div className={styles.icon_text}>
          <img
            src={temple}
            alt={t("Temple Icon")}
            className={styles.icon}
          ></img>
          <h1 className={styles.title}>{t("About Us")}</h1>
        </div>
        <div className={styles._text}>
          <p>{t("About Us Description")}</p>
          <h2>{t("Spiritual Journey Heading")}</h2>
          <p>{t("Spiritual Journey Description")}</p>
        </div>
        <div className={styles.icon_text}>
          <img
            src={temple}
            alt={t("Temple Icon")}
            className={styles.icon}
          ></img>
          <h1 className={styles.title}>{t("Meet Our Team")}</h1>
        </div>
        <div className={styles.teamProfiles}>
          <div className={styles.teamProfile}>
            <img
              src={adarshImage}
              alt={t("Adarsh Goyal Alt")}
              className={styles.profileImage}
            />
            <h3>{t("Adarsh Goyal Name")}</h3>
            <p>{t("Adarsh Goyal Title")}</p>
            <p>{t("Adarsh Goyal Description")}</p>
          </div>
          <div className={styles.teamProfile}>
            <img
              src={adityaImage}
              alt={t("Aditya Rana Alt")}
              className={styles.profileImage}
            />
            <h3>{t("Aditya Rana Name")}</h3>
            <p>{t("Aditya Rana Title")}</p>
            <p>{t("Aditya Rana Description")}</p>
          </div>
          <div className={styles.teamProfile}>
            <img
              src={sanklapImage}
              alt={t("Sanklap Asawa Alt")}
              className={styles.profileImage}
            />
            <h3>{t("Sanklap Asawa Name")}</h3>
            <p>{t("Sanklap Asawa Title")}</p>
            <p>{t("Sanklap Asawa Description")}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
